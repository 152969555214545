export const StationEnum = {
  RIVERS: 1,
  MOUNTAINS: 2,
  PLAINS: 3,
  SPACES: 4,
  SEAS: 5,
  DESERTS: 6,
  LOGOUT: 18,
};
export const StageEnum = {
  One: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  BACK: 7,
};
export const ActivityEnum = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  HOME: 13,
  BACK: 14,
};
export const SoundsEnum = {
  Login: 'assets/sounds/login.mp3',
  Stations: 'assets/sounds/stations.mp3',
  Profile: 'assets/sounds/personal-page.mp3',
  Spaces: 'assets/sounds/space.mp3',
  SpaceLessons: 'assets/sounds/activity-page.mp3',
  Seas: 'assets/sounds/seas.mp3',
  SeaLessons: 'assets/sounds/activity-page.mp3',
  Rivers: 'assets/sounds/river.mp3',
  RiverLessons: 'assets/sounds/activity-page.mp3',
  Plains: 'assets/sounds/plains.mp3',
  PlainLessons: 'assets/sounds/activity-page.mp3',
  Mountains: 'assets/sounds/mountain.mp3',
  MountainLessons: 'assets/sounds/activity-page.mp3',
  Deserts: 'assets/sounds/desert.mp3',
  DesertLessons: 'assets/sounds/activity-page.mp3',
};

export const GamesEnum = {
  1: 'monster',
  2: 'assets/sounds/stations.mp3',
  3: 'assets/sounds/personal-page.mp3',
  4: 'assets/sounds/space.mp3',
  5: 'assets/sounds/activity-page.mp3',
  6: 'assets/sounds/seas.mp3',
  7: 'assets/sounds/activity-page.mp3',
  8: 'assets/sounds/river.mp3',
  9: 'assets/sounds/activity-page.mp3',
};

export const ActivityIconEnum = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  EIGHTEEN: 18,
  NINETEEN: 19,
  TWENTY: 20,
  TWENTYONE: 21,
  TWENTYTWO: 22,
  TWENTYTHREE: 23,
  TWENTYFOUR: 24,
  TWENTYFIVE: 25,
  TWENTYSIX: 26,
};
export const TypeSubmissionEnum = {
  RECORDING: 0,
  COLORINGBOARD: 1,
};

export function reloadOnce(): void {
  if (!localStorage.getItem('firstLoad')) {
    localStorage.setItem('firstLoad', 'true');
    location.reload();
  } else {
    localStorage.removeItem('firstLoad');
  }
}

const CHARCODE_SHADDA = 1617;
const CHARCODE_SUKOON = 1618;
const CHARCODE_SUPERSCRIPT_ALIF = 1648;
const CHARCODE_TATWEEL = 1600;
const CHARCODE_ALIF = 1575;

function isCharTashkeel(letter) {
  if (typeof (letter) == 'undefined' || letter == null) {
    return false;
  }
  const code = letter.charCodeAt(0);
  return (code == CHARCODE_TATWEEL || code == CHARCODE_SUPERSCRIPT_ALIF || code >= 1612 && code <= 1631); //tashkeel
}

export function stripTashkeel(input: string) {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    const letter = input.charAt(i);
    if (!isCharTashkeel(letter)) {
      output += letter;
    }
  }
  return output;
}
