<div>
  <div class="header">
    <div class="mobile-background">
      <button (click)="toggleMenu()" title="القائمة" type="button" class="mobile-menu-button">
        <i class="fas fa-bars"></i>
      </button>
      <div class="mobile-clouds"></div>
    </div>
    <ul id="app-menu" class="menu">
      <li class="menu-item"
          [routerLink]="['/stations']"
          [routerLinkActive]="['active']">
        <i class="fa fa-graduation-cap"></i>
        <span>دروسي</span>
      </li>
      <li class="menu-item"
          [routerLink]="['/profile']"
          [routerLinkActive]="['active']">

        <i class="fas fa-info-circle"></i>
        <span>معلوماتي</span>
      </li>
      <li class="menu-item"
          [routerLink]="['/my-files']"
          [routerLinkActive]="['active']">
        <i class="fas fa-edit"></i>
        <span>أعمالي</span>
      </li>
      <li class="menu-item"
          [routerLink]="['/grades']"
          [routerLinkActive]="['active']">
        <i class="far fa-file-alt"></i>
        <span>درجاتي</span>
      </li>
      <li class="menu-item"
          [routerLink]="['/games']"
          [routerLinkActive]="['active']">
        <i class="fas fa-gamepad"></i>
        <span>ألعابي</span>
      </li>
      <li class="menu-item" [routerLinkActive]="['active']" (click)="logout()">
        <i class="fas fa-door-open"></i>
        <span>خروج</span>
      </li>

    </ul>
  </div>
  <div class="container">
    <div class="user-image">
      <img src="assets/images/characters/character-{{userPhoto}}.png" alt="user image"/>
    </div>


    <div class="login-info">
      <div *ngIf="showUserName" class="user-name">
        <div>
          الطالب : {{user.firstname + ' ' + user.lastname}}
        </div>
        <div>
          المدرسة :{{user.schoolname}}
        </div>
      </div>
      <div *ngIf="showUserName" class="user-name">
        <div> الصف: {{user.class}}</div>
        <div>المنهاج: {{user.curriculum}}</div>
      </div>
      <div *ngIf="showScore" class="score">
        <div>مجموع النقاط</div>
        <div id="user-score"> {{balanceTrunc()}}</div>
      </div>
    </div>


  </div>
</div>
