import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {H5PActivity, Lesson} from '@shared/models/lessons.model';
import {map} from 'rxjs/operators';
import {Assignment} from '@shared/models/assignment.model';
import {environment} from '@env';
import {UploadedFile} from '@shared/models/uploaded-file.model';


@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  constructor(private http: HttpClient) {
  }

  getHVPContent(courseId, hvpID): Observable<H5PActivity> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('wsfunction', 'local_user_get_hvp');
    httpParams = httpParams.set('cmid', hvpID);
    httpParams = httpParams.set('courseid', courseId);
    const opts = {params: httpParams};
    return this.http.get<H5PActivity>('', opts);
  }

}
