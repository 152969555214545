import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {CoreModule} from '@core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {AuthModule} from './pages/auth/auth.module';
import {SharedModule} from '@shared/shared.module';
import * as fromApp from '@shared/store/app.reducer';
import {StoreModule} from '@ngrx/store';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    StoreModule.forRoot(fromApp.appReducer),
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
