<mat-dialog-content>
  <div id="popup-template-1" class="popup">
    <div class="popup-backdrop"></div>
    <div class="popup-body">
      <div class="popup-back"></div>
      <div class="popup-image"></div>
      <button class="popup-close" (click)="hidePopup()"></button>
      <div class="popup-text">
        <div [innerHTML]="data.message | replaceLink" (click)="processLinks($event)"></div>
        </div>
      <button class="popup-close-btn" (click)="hidePopup()">* اغلاق *</button>
    </div>
  </div>
</mat-dialog-content>
