<body>
<div class="nav">
  <a class="nav-link" [routerLink]="['../../../',section]">
    <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 32 32">
      <g fill="currentColor" transform="translate(-14 -14) scale(1.875)">
        <rect width="4" height="4" x="14" y="14" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="20" y="14" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="8" y="14" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="14" y="8" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="20" y="8" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="8" y="8" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="14" y="20" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="20" y="20" rx="1.3" ry="1.3"/>
        <rect width="4" height="4" x="8" y="20" rx="1.3" ry="1.3"/>
      </g>
    </svg>
    <span>قائمة النشاطات</span>
  </a>
  <div class="nav-score"> {{finalGrade == null ? '-' : finalGrade}}</div>
  <a class="nav-link" style="cursor: pointer;" (click)="goToNextActivity()">
    <span>النشاط التالي</span>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <defs/>
      <g fill="currentColor">
        <path
          d="M11.4488 16.072l8.73-8.9074c1.3987-1.4405 1.3746-3.7454-.0724-5.138-1.447-1.3924-3.762-1.3684-5.1608.0721L3.8524 13.431c-.7476.7683-1.0853 1.7767-1.013 2.761-.024.9844.3377 1.9928 1.1094 2.7371l11.3827 11.0442c1.447 1.3925 3.762 1.3685 5.1608-.072 1.3987-1.4406 1.3746-3.7454-.0724-5.138z"/>
        <path
          d="M23.4344 11.1261c-2.701 0-4.8955 2.1849-4.8955 4.8739s2.1945 4.8739 4.8955 4.8739S28.33 18.689 28.33 16s-2.1945-4.8739-4.8955-4.8739z"/>
      </g>
    </svg>
  </a>
</div>
</body>
