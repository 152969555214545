<form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
    <div class="login-page">
        <div class="space">
            <div id="star-1" class="star"></div>
            <div id="star-2" class="star"></div>
            <div id="astronaut-1" class="astronaut"></div>
            <div id="space-ship-1" class="space-ship"></div>
            <div id="rocket-1" class="rocket"></div>
            <div id="earth" class="planet"></div>
            <div id="moon" class="planet"></div>
        </div>
        <div class="login-form">
            <div class="form-controls">
                <div class="login-form-labels">
                    <div>
                        اسم المستخدم
                    </div>
                    <div>
                        كلمـــة الـمـرور
                    </div>
                </div>
                <div class="login-form-controls">
                    <div>
                        <input class="input-control" type="text" id="username" name="username" ngModel required #name="ngModel">
                    </div>
                    <div>
                        <input class="input-control" type="password" id="password" name="password" ngModel required #name="ngModel">
                    </div>
                </div>
            </div>
            <button class="login-btn" [disabled]="authForm.invalid">دخول
      </button>
        </div>
        <div id="sama-logo" class="sama-logo">
            <img src="./assets/images/login/logo.png" alt="sama-logo" />
        </div>
    </div>
</form>
