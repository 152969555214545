import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {User} from '../models/user.model';
import {environment} from '@env';
import {MatDialog} from '@angular/material/dialog';
import {LogoutComponent} from '../../pages/auth/logout/logout.component';
import {H5PActivity} from '@shared/models/lessons.model';
import {MyFile} from '@shared/models/my-file.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient,
              private router: Router,
              private dialog: MatDialog) {
  }

  public currentUser(): User {
    return this.user.value;
  }

  login(username: string, password: string): Observable<User> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('username', username);
    httpParams = httpParams.set('password', password);
    httpParams = httpParams.set('service', 'moodle_mobile_app');
    const opts = {params: httpParams};
    const url = environment.loginUrl;
    return this.http.get<User>(url, opts).pipe(
      tap(response => {
          if (response.token) {
            this.router.navigate(['/']);
            this.saveUserTolocalStorage(response);
          }
        }
      )
    );
  }

  getPrivateFiles(): Observable<MyFile[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('wsfunction', 'core_files_get_files');
    httpParams = httpParams.set('contextid', '-1');
    httpParams = httpParams.set('component', 'user');
    httpParams = httpParams.set('filearea', 'private');
    httpParams = httpParams.set('itemid', '0');
    httpParams = httpParams.set('filepath', '');
    httpParams = httpParams.set('filename', '');
    httpParams = httpParams.set('contextlevel', 'user');
    httpParams = httpParams.set('instanceid', this.currentUser().userid.toString());
    const opts = {params: httpParams};
    return this.http.get<MyFile[]>('', opts).pipe(map((response: any) => {
      return response.files;
    }));
  }

  autoLogin(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.token) {
      this.user.next(user);
    } else {
      this.user.next(null);
    }
  }

  logoutDialog(): void {
    this.dialog.open(LogoutComponent);
    // this.user.next(null);
    // localStorage.removeItem('user');
    // this.router.navigate(['/login']);
  }

  logout(): void {
    this.user.next(null);
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  private saveUserTolocalStorage(user: User): void {
    this.user.next(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

}
