import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {DialogService} from '@shared/services/confirm-dialog.service';

@Injectable()
export class GlobalErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private dialogService: DialogService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // retry(1), to resend request if throw exception
      catchError((error: HttpErrorResponse) => {
        this.dialogService.open(error.error);
        if (error.status === 401) {
          localStorage.removeItem('user');
          this.router.navigate(['/login']);
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
