<!--<div class="container">-->
<!--  <div class="row">-->
<!--    <div id="loader">-->
<!--      <div class="dot"></div>-->
<!--      <div class="dot"></div>-->
<!--      <div class="dot"></div>-->
<!--      <div class="dot"></div>-->
<!--      <div class="dot"></div>-->
<!--      <div class="dot"></div>-->
<!--      <div class="dot"></div>-->
<!--      <div class="dot"></div>-->
<!--      <div class="lading"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<app-animation-loader></app-animation-loader>
