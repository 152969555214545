<app-activity-navbar
  [nextActivity]="nextActivity"
  [prevActivity]="prevActivity"
  [finalGrade]="finalGrade"
  [maxGrade]="maxGrade"
  [section]="section"
></app-activity-navbar>
<body>
<div class="container">
  <div class="content">
    <div class=" playButton">
      <button *ngIf="introAudio" class="audio_button"
              (click)="playIntro()"></button>
      <h3 class="header">{{this.intro}}</h3>
    </div>
    <div id="icon-container" [ngClass]="{'record': isRecording && !isPause }"
         class="recording-indicator-icon">
      <div class="icon-container">
        <div class="animated-circle" [ngClass]="{'animate': isRecording && !isPause }"></div>
        <i class="fas fa-microphone"></i>
      </div>

    </div>
    <div style="text-align: center">
      <h4 role="hint" class="hint"> الإجابة السابقة</h4>
      <audio #audioElement controls>
        <source [src]="lastSubmissionFile?.fileurl | safe" type="audio/webm">
      </audio>
    </div>
    <h4 *ngIf="(!isRecording || isPause) && !blobUrl" role="hint" class="hint">اضغط على الزر أدناه لتسجيل إجابتك.</h4>
    <h4 *ngIf="blobUrl" role="hint" class="hint">لقد سجلت إجابتك بنجاح! استمع إلى التسجيل أدناه.</h4>
    <div *ngIf="!blobUrl" class="timer">{{recordedTime ? recordedTime : '00:00'}}</div>
    <div class="body">
      <div *ngIf="!isRecording && !blobUrl">
        <button (click)="startRecording()" class="button record">
          <i class="fas fa-circle"></i>
          <span>سجل</span>
        </button>
      </div>

      <div>
        <button *ngIf="isRecording" (click)="abortRecording()" class="button retry">
          <i class="fas fa-undo"></i>
          <span>اعادة المحاولة</span>
        </button>
        <button *ngIf="isPause && !blobUrl" (click)="resumeRecording()" class="button record">
          <i class="fas fa-circle"></i>
          <span>اكمال</span>
        </button>
        <button *ngIf="isRecording && !isPause" (click)="pauseRecording()"
                class="button pause">
          <i class="fas fa-pause"></i>
          <span>ايقاف مؤقت</span>
        </button>
        <button *ngIf="isRecording" (click)="doneRecord()" class="button done">
          <i class="fas fa-play-circle"></i>
          <span>تم</span>
        </button>
      </div>

      <form [formGroup]="myForm" *ngIf="!isRecording && blobUrl" (ngSubmit)="saveAudioRecord()">
        <div>
          <div style="margin: 16px;">
            <audio controls>
              <source [src]="blobUrl | safe" type="audio/mpeg"/>
            </audio>
          </div>
          <a [href]="blobUrl |safe" download="audio-recorder.wav" class=" button download">
            <i class="fas fa-download"></i>
            <span>تحميل</span>
          </a>
          <button class="button save">
            <i class="fas fa-save"></i>
            <span>حفظ</span>
          </button>
          <button (click)="abortRecording()" class="button retry">
            <i class="fas fa-undo"></i>
            <span>اعادة المحاولة</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</body>
