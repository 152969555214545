import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Pipe({
  name: 'replaceLink'
})
export class ReplaceLinkPipe implements PipeTransform {

  constructor(private router: Router) {
  }

  transform(content) {
    const result = content.replace(/href=\"(.+?)\"/g, (match, p1) => {
      const id = p1.split('id=', 2)[1] ? p1.split('id=', 2)[1] : null;
      if (id) {
        const isH5p = p1.includes('mod/hvp');
        if (isH5p) {
          return `href=${this.router.url}/hvp/${id}`;
        } else {
          const isAssignment = p1.includes('mod/assign');
          if (isAssignment) {
            return `href=${this.router.url}/assign/${id}`;
          } else {
            return '';
          }
        }
      } else {
        return '';
      }
    });
    return result;
  }
}
