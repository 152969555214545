import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from './shared/services/auth.service';
import {Store} from '@ngrx/store';
import * as fromApp from './shared/store/app.reducer';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Sama-LMS';
  protected subscription = new Subscription();
  public isShowSpinner = false;

  constructor(private authService: AuthService,
              private cdRef: ChangeDetectorRef,
              private store: Store<fromApp.AppState>) {
  }

  ngAfterViewInit(): void {
    this.store.select('global').subscribe(spinnerCounter => {
      this.isShowSpinner = (spinnerCounter.spinnerCounter > 0 ? true : false);
      this.cdRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.authService.autoLogin();
  }


  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
