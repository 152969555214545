import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {SetpActivity} from '@shared/models/lessons.model';
import {ActivatedRoute, Data} from '@angular/router';
import {Assignment, File as FileSubmission} from '@shared/models/assignment.model';
import {AssignmentService} from '@shared/services/assignment.service';
import {AuthService} from '@shared/services/auth.service';
import {AudioRecordingService} from '@shared/services/audio-recording.service';
import {DynamicScriptLoaderService} from '@shared/services/DynamicScriptLoaderService';
import {UploadedFile} from '@shared/models/uploaded-file.model';
import {UploadFileService} from '@shared/services/uploadFile.service';

declare function stopAudio(): any;

declare function initial(image: any): any;

@Component({
  selector: 'app-coloring-board-assignment',
  templateUrl: './coloring-board-assignment.component.html',
  styleUrls: [
    './coloring-board-assignment.component.css',
  ],
})
export class ColoringBoardAssignmentComponent implements OnInit {
  scripts: string[] = [
    'coloring-board-1', 'coloring-board-2', 'coloring-board-3', 'coloring-board-4'
  ];

  nextActivity: SetpActivity;
  prevActivity: SetpActivity;
  finalGrade: number;
  maxGrade: number;
  section: string;
  intro: string;
  introAudio;
  introImage = null;
  uploadedFile: UploadedFile;
  assignment: Assignment;
  formData;

  constructor(private route: ActivatedRoute,
              private assignmentService: AssignmentService,
              private auth: AuthService,
              private fileService: UploadFileService,
              private dynamicScriptLoader: DynamicScriptLoaderService,
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      stopAudio();
    }, 501);

    this.dynamicScriptLoader.load(this.scripts).then(data => {
      setTimeout(() => {
        // tslint:disable-next-line:no-shadowed-variable
        this.route.data.subscribe((data: Data) => {
          this.assignment = data.assignmentActivity;
          this.prepareData();
        });
      }, 1);
    }).catch(error => console.log(error));

  }


  private prepareData(): void {
    this.prevActivity = this.assignment.prevactivity;
    this.nextActivity = this.assignment.nextactivity;
    this.maxGrade = this.assignment.maxgrade;
    this.finalGrade = this.assignment.finalgrade;
    this.section = this.assignment.section;
    this.addLastFileSubmission();
  }

  private addLastFileSubmission(): void {
    this.intro = this.assignment.intro;
    const introUrl = this.getAudioURL();
    this.introAudio = new Audio(introUrl);
    this.introImage = this.getImageURL();
    initial(this.introImage);
  }

  async uploadImage(): Promise<void> {
    this.formData = new FormData();
    // @ts-ignore
    const imageData = $(`#wPaint`).wPaint('image');
    const file = this.fileService.dataURLtoFile(imageData, `${this.intro}.png`);
    this.formData.set('file', file, file.name);
    await this.assignmentService.upload(this.formData).toPromise()
      .then(res => {
        return this.uploadedFile = res[0];
      });
  }

  async saveImage(): Promise<void> {
    const id = this.route.snapshot.params.id;
    await this.uploadImage();
    this.assignmentService.save(id, this.uploadedFile.itemid.toString()).subscribe((res) => {
      this.fileService.saveToPrivateFiles(this.formData).subscribe();
    });
  }

  private getImageURL(): string {
    const token = this.auth.user.value.token;
    const introattachments = this.assignment?.introattachments;
    let url = null;
    if (introattachments?.[0]?.fileurl.match(/.(jpg|jpeg|png|gif)$/i)) {
      url = introattachments?.[0]?.fileurl + '?token=' + token;
    } else if (introattachments?.[1]?.fileurl.match(/.(jpg|jpeg|png|gif)$/i)) {
      url = introattachments?.[1]?.fileurl + '?token=' + token;
    }
    return url;
  }

  private getAudioURL(): string {
    const token = this.auth.user.value.token;
    const introattachments = this.assignment?.introattachments;
    let url = null;
    if (introattachments?.[0]?.fileurl.match(/.(mp3|wav|wma|m4a|ogg)$/i)) {
      url = introattachments?.[0]?.fileurl + '?token=' + token;
    } else if (introattachments?.[1]?.fileurl.match(/.(mp3|wav|wma|m4a|ogg)$/i)) {
      url = introattachments?.[1]?.fileurl + '?token=' + token;
    }
    return url;
  }

  playIntro(): void {
    if (this.introAudio?.paused) {
      this.introAudio?.play();
    } else {
      this.introAudio?.pause();
      this.introAudio.currentTime = 0;
    }
  }
}
