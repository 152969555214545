import {Component, Input, OnInit} from '@angular/core';
import {SetpActivity} from '@shared/models/lessons.model';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '@shared/services/confirm-dialog.service';
import {TypeSubmissionEnum} from '@shared/shared';

@Component({
  selector: 'app-activity-navbar',
  templateUrl: './activity-navbar.component.html',
  styleUrls: ['./activity-navbar.component.css']
})
export class ActivityNavbarComponent implements OnInit {
  @Input() nextActivity: SetpActivity;
  @Input() prevActivity: SetpActivity;
  @Input() finalGrade: number;
  @Input() maxGrade: number;
  @Input() section: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: DialogService) {
  }

  ngOnInit(): void {
  }

  goToNextActivity(): void {
    if (this.nextActivity?.id) {
      if (this.nextActivity.uservisible) {
        if (this.nextActivity.modname === 'assign') {
          let routName = '';
          switch (this.nextActivity.typesubmission) {
            case TypeSubmissionEnum.RECORDING: {
              routName = 'recording-assign';
              break;
            }
            case TypeSubmissionEnum.COLORINGBOARD: {
              routName = 'coloring-board-assign';
              break;
            }
          }
          this.router.navigate(['../../' + routName, this.nextActivity.id], {relativeTo: this.route});
        } else {
          this.router.navigate(['../../' + this.nextActivity.modname, this.nextActivity.id], {relativeTo: this.route});
        }
      } else {
        this.dialog.open(this.nextActivity.availabilityinfo);
      }
    }
  }
}
