import {Component, OnInit} from '@angular/core';
import {AuthService} from '@shared/services/auth.service';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {SoundsEnum} from '@shared/shared';
import {DialogService} from '@shared/services/confirm-dialog.service';

declare function playAudio(src): any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: DialogService) {
  }

  ngOnInit(): void {
    playAudio(SoundsEnum.Login);
  }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    const name = form.value.username;
    const password = form.value.password;
    // const uuid = UUID.UUID();
    this.authService.login(name, password).subscribe(
      (res: any) => {
        if (res.error) {
          this.dialog.open(res.error);
        } else {
          this.router.navigate(['/']);
        }
      },
    );
  }
}
