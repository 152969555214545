import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Route, Router} from '@angular/router';

@Component({
  selector: 'app-dialog-finish-time',
  templateUrl: './dialog-finish-time.component.html',
  styleUrls: ['./dialog-finish-time.component.css']
})
export class DialogFinishTimeComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogFinishTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit() {
  }

  hidePopup(): void {
    this.dialogRef.close();
    this.router.navigate(['games']);
  }

}
