import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Route, Router} from '@angular/router';

@Component({
  selector: 'app-start-play-now',
  templateUrl: './start-play-now.component.html',
  styleUrls: ['./start-play-now.component.css']
})
export class StartPlayNowComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<StartPlayNowComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit() {
  }

  hidePopup(): void {
    this.dialogRef.close();
  }

  StartPlay(): void {
    this.router.navigate(['games']);
    this.hidePopup();
  }

}
