import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '@shared/components/dialog/dialog.component';
import {DialogFinishTimeComponent} from '@shared/components/dialog-finish-time/dialog-finish-time.component';
import {StartPlayNowComponent} from '@shared/components/start-play-now/start-play-now.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  open(message: string): void {
    this.dialog.open(DialogComponent,
      {
        panelClass: 'custom-dialog-container',
        width: '50vw',
        data: {message},
      },
    );
  }

  openFinishTimeDialog(): void {
    this.dialog.open(DialogFinishTimeComponent,
      {
        panelClass: 'custom-dialog-container',
        width: '50vw',
      },
    );
  }
  StartPlayNowDialog(): void {
    this.dialog.open(StartPlayNowComponent,
      {
        panelClass: 'custom-dialog-container',
        width: '50vw',
      },
    );
  }
}
