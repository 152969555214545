import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {H5PActivity, SetpActivity} from '@shared/models/lessons.model';
import {DialogService} from '@shared/services/confirm-dialog.service';
import {ActivityService} from '@shared/services/activity.service';
import {UploadFileService} from '@shared/services/uploadFile.service';

declare function stopAudio(): any;

@Component({
  selector: 'app-hvp',
  templateUrl: './hvp.component.html',
  styleUrls: ['./hvp.component.css']
})
export class HvpComponent implements OnInit, OnDestroy {
  routeReuseStrategySubscription;
  url: string;
  nextActivity: SetpActivity;
  prevActivity: SetpActivity;
  finalGrade: number;
  maxGrade: number;
  section: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: DialogService,
              private fileService: UploadFileService,
              private activityService: ActivityService,
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    this.routeReuseStrategySubscription = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      stopAudio();
    }, 501);
    this.route.data.subscribe((data: Data) => {
        if (data?.hvpActivity?.url) {
          this.section = data.hvpActivity.section;
          this.url = data.hvpActivity.url;
          this.prevActivity = data.hvpActivity.prevactivity;
          this.nextActivity = data.hvpActivity.nextactivity;
          this.maxGrade = data.hvpActivity.maxgrade;
          this.finalGrade = data.hvpActivity.finalgrade;
        } else {
          this.router.navigate(['../../'], {relativeTo: this.route});
        }
      }
    );
  }

  @HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent): void {
    if (event.data.context === 'h5p' && event.data.action === 'setFinished') {
      const hvpId = this.route.snapshot.params.id;
      const courseId = this.route.snapshot.params.courseId;
      setTimeout(() => {
        this.activityService.getHVPContent(courseId, hvpId).subscribe((res: H5PActivity) => {
          this.section = res.section;
          this.prevActivity = res.prevactivity;
          this.nextActivity = res.nextactivity;
          this.maxGrade = res.maxgrade;
          this.finalGrade = res.finalgrade;
          if (this.nextActivity.section != this.section) {
            this.dialog.StartPlayNowDialog();
          }
        });
      }, 1500);
    }
    if (event.data.context === 'h5p-coloring-board' && event.data.action === 'downloadImage') {
      const hvpId = this.route.snapshot.params.id;
      const imageData = event.data.imageData;
      const file = this.fileService.dataURLtoFile(imageData, `image-${hvpId}.png`);
      const formData = new FormData();
      formData.set('file', file, file.name);
      this.fileService.saveToPrivateFiles(formData).subscribe();
    }
  }


  ngOnDestroy(): void {
    if (this.routeReuseStrategySubscription) {
      this.routeReuseStrategySubscription.unsubscribe();
    }
  }

}
