import {Injectable} from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {name: 'RiverLessons', src: '../../../assets/js/RiverLessons.js'},
  {name: 'SpaceLessons', src: '../../../assets/js/SpaceLessons.js'},
  {name: 'MountainLessons', src: '../../../assets/js/MountainLessons.js'},
  {name: 'PlainLessons', src: '../../../assets/js/PlainLessons.js'},
  {name: 'SeaLessons', src: '../../../assets/js/SeaLessons.js'},
  {name: 'DesertLessons', src: '../../../assets/js/DesertLessons.js'},
  {name: 'Plains', src: '../../../assets/js/Plains.js'},
  {name: 'Rivers', src: '../../../assets/js/Rivers.js'},
  {name: 'Spaces', src: '../../../assets/js/Spaces.js'},
  {name: 'Mountains', src: '../../../assets/js/Mountains.js'},
  {name: 'Deserts', src: '../../../assets/js/Deserts.js'},
  {name: 'Seas', src: '../../../assets/js/Seas.js'},
  {name: 'Stations', src: '../../../assets/js/Stations.js'},
  {name: 'h5p-resizer', src: '../../../assets/js/h5p-resizer.js'},

  {name: 'monster-1', src: '../../../assets/games/monster/c2runtime.js'},
  {name: 'monster-2', src: '../../../assets/games/monster/sw.js'},
  {name: 'monster-3', src: '../../../assets/games/monster/initial.js'},

  {name: 'happy-cups-1', src: '../../../assets/games/happy-cups/c2runtime.js'},
  {name: 'happy-cups-2', src: '../../../assets/games/happy-cups/initial.js'},

  {name: 'tetris-1', src: '../../../assets/games/tetris/scripts/supportcheck.js'},
  {name: 'tetris-2', src: '../../../assets/games/tetris/scripts/offlineclient.js'},
  {name: 'tetris-3', src: '../../../assets/games/tetris/scripts/main.js'},
  {name: 'tetris-4', src: '../../../assets/games/tetris/scripts/register-sw.js'},

  {name: 'galactic-war-1', src: '../../../assets/games/galactic-war/js/initial.js'},
  {name: 'galactic-war-2', src: '../../../assets/games/galactic-war/js/howler.min.js'},
  {name: 'galactic-war-3', src: '../../../assets/games/galactic-war/js/main.js'},

  {name: 'soccer-1', src: '../../../assets/games/soccer/js/vendor/detectmobilebrowser.js'},
  {name: 'soccer-2', src: '../../../assets/games/soccer/js/vendor/TweenMax.min.js'},
  {name: 'soccer-3', src: '../../../assets/games/soccer/js/vendor/js.cookie.js'},
  {name: 'soccer-4', src: '../../../assets/games/soccer/js/plugins.js'},
  {name: 'soccer-5', src: '../../../assets/games/soccer/js/sound.js'},
  {name: 'soccer-6', src: '../../../assets/games/soccer/js/canvas.js'},
  {name: 'soccer-7', src: '../../../assets/games/soccer/js/championship.js'},
  {name: 'soccer-8', src: '../../../assets/games/soccer/js/team.js'},
  {name: 'soccer-9', src: '../../../assets/games/soccer/js/player.js'},
  {name: 'soccer-10', src: '../../../assets/games/soccer/js/func.js'},
  {name: 'soccer-11', src: '../../../assets/games/soccer/js/game.js'},
  {name: 'soccer-12', src: '../../../assets/games/soccer/js/mobile.js'},
  {name: 'soccer-13', src: '../../../assets/games/soccer/js/main.js'},
  {name: 'soccer-14', src: '../../../assets/games/soccer/js/loader.js'},
  {name: 'soccer-15', src: '../../../assets/games/soccer/js/init.js'},
  {name: 'soccer-16', src: '../../../assets/games/soccer/js/vendor/modernizr-2.6.2.min.js'},

  {name: 'sorcerer-1', src: '../../../assets/games/sorcerer/js/howler.min.js'},
  {name: 'sorcerer-2', src: '../../../assets/games/sorcerer/js/main.js'},
  {name: 'sorcerer-3', src: '../../../assets/games/sorcerer/js/init.js'},

  {name: 'katana-fruits-1', src: '../../../assets/games/katana-fruits/js/easeljs-NEXT.min.js'},
  {name: 'katana-fruits-2', src: '../../../assets/games/katana-fruits/js/howler.min.js'},
  {name: 'katana-fruits-3', src: '../../../assets/games/katana-fruits/js/main.js'},
  {name: 'katana-fruits-4', src: '../../../assets/games/katana-fruits/js/init.js'},

  {name: 'car-rush-1', src: '../../../assets/games/car-rush/js/howler.min.js'},
  {name: 'car-rush-2', src: '../../../assets/games/car-rush/js/main.js'},
  {name: 'car-rush-3', src: '../../../assets/games/car-rush/js/init.js'},

  {name: 'star-defense-1', src: '../../../assets/games/star-defense/scripts/supportcheck.js'},
  {name: 'star-defense-2', src: '../../../assets/games/star-defense/scripts/offlineclient.js'},
  {name: 'star-defense-3', src: '../../../assets/games/star-defense/scripts/main.js'},
  {name: 'star-defense-4', src: '../../../assets/games/star-defense/scripts/register-sw.js'},
  {name: 'star-defense-5', src: '../../../assets/games/star-defense/initial.js'},

  {name: 'swipe-ball-1', src: '../../../assets/games/swipe-ball/scripts/supportcheck.js'},
  {name: 'swipe-ball-2', src: '../../../assets/games/swipe-ball/scripts/offlineclient.js'},
  {name: 'swipe-ball-3', src: '../../../assets/games/swipe-ball/scripts/main.js'},
  {name: 'swipe-ball-4', src: '../../../assets/games/swipe-ball/scripts/register-sw.js'},
  {name: 'swipe-ball-5', src: '../../../assets/games/swipe-ball/initial.js'},

  {name: 'coloring-board-1', src: '../../../assets/js/coloring-board/wColorPicker.min.js'},
  {name: 'coloring-board-2', src: '../../../assets/js/coloring-board/plugins/shapes/wPaint.menu.main.shapes.min.js'},
  {name: 'coloring-board-3', src: '../../../assets/js/coloring-board/plugins/file/wPaint.menu.main.file.min.js'},
  {name: 'coloring-board-4', src: '../../../assets/js/coloring-board/script.js'},
];

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      }
    });
  }

}
