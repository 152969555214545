import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {SetpActivity} from '@shared/models/lessons.model';
import {Assignment, File as FileSubmission} from '@shared/models/assignment.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Data} from '@angular/router';
import {UploadedFile} from '@shared/models/uploaded-file.model';
import {AssignmentService} from '@shared/services/assignment.service';
import {AuthService} from '@shared/services/auth.service';
import {AudioRecordingService} from '@shared/services/audio-recording.service';
import {UploadFileService} from '@shared/services/uploadFile.service';

declare function stopAudio(): any;

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css']
})
export class AssignmentComponent implements OnInit, OnDestroy, AfterViewInit {
  nextActivity: SetpActivity;
  prevActivity: SetpActivity;
  finalGrade: number;
  maxGrade: number;
  file: File;
  lastSubmissionFile: FileSubmission;
  uploadedFile: UploadedFile;
  isRecording = false;
  isPause = false;
  section: string;
  recordedTime;
  blobUrl;
  intro;
  introAudio;
  formData;
  @ViewChild('audioElement') playerRef: ElementRef;

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    fileSource: new FormControl('', [Validators.required])
  });

  constructor(private route: ActivatedRoute,
              private assignmentService: AssignmentService,
              private auth: AuthService,
              private fileService: UploadFileService,
              private audioRecordingService: AudioRecordingService,
  ) {
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.blobUrl = URL.createObjectURL(data.blob);
      this.myForm.patchValue({
        fileSource: new File([data.blob], this.intro + '.mp3', {type: 'audio/webm'})
      });
    });
  }


  get f() {
    return this.myForm.controls;
  }

  get $player(): HTMLAudioElement {
    return this.playerRef?.nativeElement;
  }

  ngOnInit(): void {
    setTimeout(() => {
      stopAudio();
    }, 501);
    this.route.data.subscribe((data: Data) => {
      this.prepareData(data.assignmentActivity);
    });

  }


  private prepareData(assignment: Assignment): void {
    this.prevActivity = assignment.prevactivity;
    this.nextActivity = assignment.nextactivity;
    this.maxGrade = assignment.maxgrade;
    this.finalGrade = assignment.finalgrade;
    this.section = assignment.section;
    this.addLastFileSubmission(assignment);
  }


  private addLastFileSubmission(assignment: Assignment): void {
    this.intro = assignment.intro;
    const token = this.auth.user.value.token;
    this.lastSubmissionFile = assignment?.lastattempt?.submission?.plugins[0]?.fileareas[0]?.files[0];
    if (this.lastSubmissionFile?.fileurl) {
      this.lastSubmissionFile.fileurl = this.lastSubmissionFile.fileurl + '?token=' + token;
      this.$player?.load();
    }
    const introattachment = assignment?.introattachments?.[0];
    if (introattachment?.fileurl) {
      const introUrl = introattachment.fileurl + '?token=' + token;
      this.introAudio = new Audio(introUrl);
    }
  }

  async uploadAudioRecord(): Promise<void> {
    this.formData = new FormData();
    const file = this.myForm.get('fileSource').value;
    this.formData.set('file', file, file.name);
    await this.assignmentService.upload(this.formData).toPromise()
      .then(res => {
        return this.uploadedFile = res[0];
      });
  }

  async saveAudioRecord(): Promise<void> {
    const id = this.route.snapshot.params.id;
    await this.uploadAudioRecord();
    this.assignmentService.save(id, this.uploadedFile.itemid.toString()).subscribe((res) => {
      this.isRecording = false;
      this.lastSubmissionFile.fileurl = this.blobUrl;
      this.blobUrl = null;
      this.isPause = false;
      this.recordedTime = null;
      this.$player?.load();
      this.fileService.saveToPrivateFiles(this.formData).subscribe();
    });
  }

  startRecording(): void {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording(): void {
    if (this.isRecording) {
      this.audioRecordingService.abortRecording();
    }
    this.isRecording = false;
    this.isPause = false;
    this.recordedTime = null;
    this.blobUrl = null;
  }

  pauseRecording(): void {
    if (this.isRecording) {
      this.audioRecordingService.pauseRecording();
    }
    this.isPause = true;
  }

  resumeRecording(): void {
    if (this.isPause) {
      this.audioRecordingService.resumeRecording();
    }
    this.isPause = false;
  }

  doneRecord(): void {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
      this.blobUrl = null;
    }
  }


  playIntro(): void {
    if (this.introAudio?.paused) {
      this.introAudio?.play();
    } else {
      this.introAudio?.pause();
      this.introAudio.currentTime = 0;
    }
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  ngAfterViewInit(): void {
    this.$player.load();
  }
}
