import {NgModule} from '@angular/core';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {DialogComponent} from './components/dialog/dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AnimationLoaderComponent} from './components/animation-loader/animation-loader.component';
import {CanvasComponent} from './components/canvas/canvas.component';
import {IframComponent} from './components/ifram/ifram.component';
import {SafePipe} from './pipes/safe.pipe';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {HvpComponent} from '@shared/components/hvp/hvp.component';
import {HeaderComponent} from './components/header/header.component';
import {ActivityNavbarComponent} from './components/activity-navbar/activity-navbar.component';
import {AssignmentComponent} from './components/assignment/assignment.component';
import {ReplaceLinkPipe} from '@shared/pipes/replaceLink.pipe';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import {DialogFinishTimeComponent} from '@shared/components/dialog-finish-time/dialog-finish-time.component';
import {StartPlayNowComponent} from '@shared/components/start-play-now/start-play-now.component';
import { ColoringBoardAssignmentComponent } from './components/coloring-board-assignment/coloring-board-assignment.component';

const materialModules = [
  MatExpansionModule,
  MatListModule,
  MatDialogModule,
  MatCardModule,
  MatTableModule
];

@NgModule({
  declarations: [
    HvpComponent,
    SpinnerComponent,
    DialogComponent,
    AnimationLoaderComponent,
    CanvasComponent,
    IframComponent,
    SafePipe,
    ReplaceLinkPipe,
    BackButtonComponent,
    HeaderComponent,
    ActivityNavbarComponent,
    AssignmentComponent,
    DialogFinishTimeComponent,
    StartPlayNowComponent,
    ColoringBoardAssignmentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ...materialModules
  ],
  exports: [
    SpinnerComponent,
    AnimationLoaderComponent,
    CanvasComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    DialogComponent,
    DialogFinishTimeComponent,
    StartPlayNowComponent,
    IframComponent,
    HvpComponent,
    SafePipe,
    ReplaceLinkPipe,
    HeaderComponent,
    ...materialModules
  ],
})

export class SharedModule {
}
