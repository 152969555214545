import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {H5PActivity, Lesson} from '@shared/models/lessons.model';
import {map} from 'rxjs/operators';
import {Assignment} from '@shared/models/assignment.model';
import {environment} from '@env';
import {UploadedFile} from '@shared/models/uploaded-file.model';


@Injectable({
  providedIn: 'root'
})
export class AssignmentService {
  constructor(private http: HttpClient) {
  }

  getAssignmentStatus(assignId: any): Observable<Assignment> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('wsfunction', 'mod_assign_get_submission_status');
    httpParams = httpParams.set('id', assignId);
    const opts = {params: httpParams};
    return this.http.get<Assignment>('', opts);
  }

  upload(file: FormData): Observable<UploadedFile[]> {
    const url = environment.uploadFileURL;
    return this.http.post<UploadedFile[]>(url, file);
  }

  save(id: string, itemId: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('wsfunction', 'mod_assign_save_submission');
    httpParams = httpParams.set('id', id);
    httpParams = httpParams.set('plugindata[files_filemanager]', itemId);
    const opts = {params: httpParams};
    return this.http.get<any>('', opts);
  }

}
