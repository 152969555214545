import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Course, H5PActivity, Lesson} from '@shared/models/lessons.model';
import {map, mergeMap} from 'rxjs/operators';
import {Assignment} from '@shared/models/assignment.model';
import {environment} from '@env';
import {UploadedFile} from '@shared/models/uploaded-file.model';


@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  constructor(private http: HttpClient) {
  }

  upload(file: FormData): Observable<UploadedFile> {
    const url = environment.uploadFileURL;
    return this.http.post<UploadedFile>(url, file).pipe(
      map(response => {
        return response[0];
      })
    );
  }

  saveToPrivateFiles(file: FormData) {
    return this.upload(file).pipe(
      mergeMap((uploadedFile) => {
        return this.save(uploadedFile);
      })
    );
  }

  save(uploadedFile: UploadedFile): Observable<void> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('wsfunction', 'core_user_add_user_private_files');
    httpParams = httpParams.set('draftid', uploadedFile.itemid.toString());
    const opts = {params: httpParams};
    return this.http.get<void>('', opts);
  }

  dataURLtoFile(dataurl, filename): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }
}
