import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {exhaustMap, take} from 'rxjs/operators';

import {AuthService} from '@shared/services/auth.service';
import {environment} from '@env';
import {BehaviorSubject} from 'rxjs';
import {User} from '@shared/models/user.model';
import {Router} from '@angular/router';

const baseUrl = environment.baseUrl;

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {
  }

  intercept(req: HttpRequest<BehaviorSubject<User>>, next: HttpHandler) {
    return this.authService.user.pipe(
      take(1),
      exhaustMap(user => {
        if (!user) {
          return next.handle(req);
        }
        let params = req.params;
        let modifiedReq;
        if (req.method === 'POST') {
          params = params.append('token', user.token);
          modifiedReq = req.clone({params});
          return next.handle(modifiedReq);
        } else {
          params = params.append('wstoken', user.token);
          params = params.append('moodlewsrestformat', 'json');
          modifiedReq = req.clone({params});
          const modifiedURLReq = modifiedReq.clone(
            {url: baseUrl}
          );
          return next.handle(modifiedURLReq);
        }
      })
    );
  }
}
