import * as fromSpinner from '../components/spinner/store/spinner.reducer';
import {ActionReducerMap} from '@ngrx/store';

export interface AppState {
  global: fromSpinner.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  global: fromSpinner.reducer
};

