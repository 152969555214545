import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '@shared/services/auth.service';
import {User} from '@shared/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() showScore = true;
  @Input() showUserName = true;
  @Input() balance;
  @Input() userPhoto;
  user: User;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.userPhoto = +localStorage.getItem('userCharacter') + 1;
    this.user = this.authService.currentUser();
  }

  logout(): void {
    this.authService.logoutDialog();
  }

  balanceTrunc(): number {
    return Math.trunc(this.balance);
  }

  toggleMenu(): void {
    const menu: any = document.querySelector('#app-menu');
    if (menu) {
      if (menu.classList.contains('show')) {
        menu.style.opacity = 0;
        menu.style.transform = 'translateY(-2.5rem)';
        setTimeout(() => {
          menu.classList.remove('show');
          menu.style.opacity = 1;
          menu.style.transform = '';
        }, 250);

      } else {
        menu.style.opacity = 0;
        menu.style.transform = 'translateY(-2.5rem)';
        menu.classList.add('show');
        setTimeout(() => {
          menu.style.opacity = 1;
          menu.style.transform = '';
        }, 10);
      }
    }
  }
}
