import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from '@core/interceptors/auth-interceptor.service';
import {GlobalErrorInterceptor} from '@core/interceptors/global-error.interceptor';
import {SpinnerInterceptorService} from '@core/interceptors/spinner-interceptor.service';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalErrorInterceptor,
      multi: true
    },
  ]
})
export class CoreModule {

}
